
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import moment from 'moment';

@Component({
  name: 'Calendar',
})
export default class extends Vue {
  @Prop({ required: true }) private calendarType!: any;

  @Prop({ required: true }) private selectedDay!: any;

  @Prop() private calendarData!: any;

  @Prop({ default: false }) private prevChoiceFlag!: any;

  mounted() {
    this.getCalendarInfo();
    this.calendarDataClone = {
      ...this.calendarDataClone,
    };
  }

  private calendarDataClone: any = null;

  private str = '';

  private startDate: Date | null = null;

  private endDate: Date | null = null;

  private count = 0;

  private today = new Date();

  private todayClone = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate())

  private todayYear = new Date().getFullYear();

  private todayMonth = new Date().getMonth() + 1;

  @Watch('calendarData', { deep: true })
  private getCalendarInfo() {
    this.$nextTick(() => {
      this.currentCalendar();
      this.calendarDataClone = {
        ...this.calendarDataClone,
        ...this.calendarData,
      };
      // 선택정보 유지
      // if (this.startDate && this.endDate) {
      //   const startCellId = document.getElementById(`cell-${this.startDate.getMonth() + 1}-${this.startDate.getDate()}`)?.parentElement;
      //   const endCellId = document.getElementById(`cell-${this.endDate.getMonth() + 1}-${this.endDate.getDate()}`)?.parentElement;
      //   if (startCellId && endCellId) {
      //     startCellId.classList.add('click');
      //     endCellId.classList.add('click');
      //     this.choiceBetweenDate();
      //   }
      // }
      this.daysCheck(this.startDate, this.endDate);
      if (this.selectedDay && this.calendarType === 0) this.checkClickClass(new Date(this.selectedDay), moment(this.selectedDay, 'YYYY-MM-DD').format('D'));
    });
  }

  private async currentCalendar() {
    const firstDate = new Date(this.todayYear, this.todayMonth - 1, 1);
    const lastDate = new Date(this.todayYear, this.todayMonth, 0);
    const nextDate = new Date(this.todayYear, this.todayMonth, 1);
    let tbodyCalendar: any = (document.getElementById('calendar') as HTMLTableElement).tBodies[0];

    if (tbodyCalendar === undefined) tbodyCalendar = document.getElementById('calendar') as HTMLTableElement;

    while (tbodyCalendar.rows.length > 0) tbodyCalendar.deleteRow(tbodyCalendar.rows.length - 1);

    let row = null;
    row = tbodyCalendar.insertRow();

    let count = 0;

    for (let i = 0; i < firstDate.getDay(); i += 1) {
      const cell = row.insertCell();
      count += 1;
    }
    for (let i = 1; i <= lastDate.getDate(); i += 1) {
      const cell = row.insertCell();
      if (this.calendarType === 3) {
        this.str = `<div class="cell__date line" id="cell-${firstDate.getMonth() + 1}-${i}"><div class="bg">${i}</div></div>`;
      } else {
        this.str = `<div class="cell__date line" id="cell-${firstDate.getMonth() + 1}-${i}"><div class="bg">${i}</div></div>`;
      }
      cell.classList.add('date-cell');

      let disabled = false;

      // eslint-disable-next-line @typescript-eslint/no-inferrable-types
      const clickDate: string = `${firstDate.getFullYear()}.${firstDate.getMonth() + 1}.${i}`;

      const compareDay = `${firstDate.getFullYear()}-${firstDate.getMonth() + 1}-${i}`;
      const datePattern = 'YYYY-MM-DD';

      if (this.statusCheck(compareDay)) {
        disabled = true;
      }

      const dateInfo = this.calendarData[moment(compareDay, 'YYYY-MM-DD').format('YYYY-MM-DD')];

      if (dateInfo) {
        if (disabled && moment(compareDay, 'YYYY-MM-DD').format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) { // 예약불가 && 오늘이 아닌경우
          if (moment(compareDay, 'YYYY-MM-DD').isAfter(moment())) {
            if (dateInfo && dateInfo.reservationMaxCount <= dateInfo.reservationNowCount) {
              cell.classList.add('full');
              cell.classList.add('cell--reservation-disable');
              // this.str += `(${this.calendarData[moment(compareDay, 'YYYY-MM-DD').format(datePattern)].reservationNowCount}/${this.calendarData[moment(compareDay, 'YYYY-MM-DD').format(datePattern)].reservationMaxCount})`;
            } else {
              cell.classList.add('cell--reservation-disable');
            }
          } else {
            cell.classList.add('cell--reservation-disable');
          }
          cell.innerHTML = this.str;
        } else if (disabled && moment(compareDay, 'YYYY-MM-DD').format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) { // 예약불가 && 오늘인 경우
          // this.str += `(${this.calendarData[moment(compareDay, 'YYYY-MM-DD').format(datePattern)].reservationNowCount}/${this.calendarData[moment(compareDay, 'YYYY-MM-DD').format(datePattern)].reservationMaxCount})`;
          cell.classList.add('cell--reservation-disable');
          cell.innerHTML = this.str;
        } else if (!disabled) { // 예약가능한 경우
          if (this.calendarData[moment(compareDay, 'YYYY-MM-DD').format('YYYY-MM-DD')].holidayStatus === 0) { // 휴무일이 아닌 경우
            // this.str += `(${this.calendarData[moment(compareDay, 'YYYY-MM-DD').format(datePattern)].reservationNowCount}/${this.calendarData[moment(compareDay, 'YYYY-MM-DD').format(datePattern)].reservationMaxCount})`;
            cell.innerHTML = this.str;
          }
        }
      }

      cell.innerHTML = this.str;

      // 이전날짜 비활성화 클래스 적용
      if (this.prevChoiceFlag === false && moment(compareDay, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'))) {
        cell.classList.add('cell--reservation-disable');
      }

      count += 1;
      this.str = '';

      const dayNumber = count % 7;

      cell.addEventListener('click', () => {
        this.choiceDate(clickDate, new Date(firstDate.getFullYear(), firstDate.getMonth(), i), i, disabled);
      });

      if (count % 7 === 1) cell.style.color = '#16ADD5';
      if (count % 7 === 0) {
        cell.style.color = '#2D5298';
        row = tbodyCalendar.insertRow();
      }
    }
    if (nextDate.getDay() !== 0) {
      for (let i = 0; i < 7 - nextDate.getDay(); i += 1) {
        const cell = row.insertCell();
        count += 1;
      }
    }
  }

  private statusCheck(date: string) {
    const dateInfo = this.calendarData[moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')];
    if (!dateInfo) return false;
    if (dateInfo.reservationMaxCount <= dateInfo.reservationNowCount) return true;
    if (dateInfo.holidayStatus === 1) return true;
    if (dateInfo.closeStatus === 1) return true;
    if (dateInfo.standbyStatus === 1) return true;
    return false;
  }

  private statusCheckClone(date: string) {
    const dateInfo = this.calendarDataClone[moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')];
    if (dateInfo.holidayStatus === 1) return true;
    if (dateInfo.closeStatus === 1) return true;
    if (dateInfo.standbyStatus === 1) return true;
    return false;
  }

  private removeClickClass() {
    const clicks = document.getElementsByClassName('click');
    while (clicks.length > 0) {
      clicks[0].classList.remove('click');
    }
    this.calendarDataClone = this.calendarData;
  }

  private removeActiveClass() {
    const elements = document.querySelectorAll('.date-cell.selected');
    elements.forEach((element) => {
      element.classList.remove('selected');
    });
    const elements2 = document.querySelectorAll('.date-cell.click');
    elements2.forEach((element) => {
      element.classList.remove('click');
    });
    const elements3 = document.querySelectorAll('.date-cell.selected-start');
    const elements4 = document.querySelectorAll('.date-cell.selected-end');
    elements3.forEach((element) => {
      element.classList.remove('selected-start');
    });
    elements4.forEach((element) => {
      element.classList.remove('selected-end');
    });
    this.calendarDataClone = this.calendarData;
  }

  private daysCheck(startDate: any, endDate: any) {
    if (startDate && endDate) {
      let curDate = startDate;
      const lastDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
      while (curDate <= endDate) {
        this.count += 1;
        const date = `${curDate.getFullYear()}-${curDate.getMonth() + 1}-${curDate.getDate()}`;
        curDate = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate() + 1);

        // 공휴일, 방막기, 최소예약날짜 체크 (체험/레저)
        // 숙박은 검증 안함 - dateInfo이 없음
        // 공휴일이면 true 아니면 false반환 (숙박은 무조건 false 반환)
        const dateInfo = this.calendarDataClone[moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')];
        if (!dateInfo || !this.statusCheckClone(date)) {
          const dayId = document.getElementById(`cell-${(curDate.getDate() - 1 === 0) ? curDate.getMonth() : curDate.getMonth() + 1}-${(curDate.getDate() - 1 === 0) ? lastDate.getDate() : curDate.getDate() - 1}`)?.parentElement;

          if (dayId !== null && dayId !== undefined
          && (this.isSameDay(new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate() - 1), startDate)
          || this.isSameDay(new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate() - 1), endDate))) {
            dayId.classList.add('click');
          } else if (dayId !== null && dayId !== undefined) dayId.classList.add('selected');

          // 복수 날짜 선택이 가능한 경우
          if (this.calendarType !== 4) {
            if (dayId !== null && dayId !== undefined && (this.isSameDay(new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate() - 1), startDate))) {
              dayId.classList.add('selected-start');
            } else if (dayId !== null && dayId !== undefined && (this.isSameDay(new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate() - 1), endDate))) {
              dayId.classList.add('selected-end');
            }
          }
        } else {
          return true;
        }
      }
    }
    return false;
  }

  private isSameDay(target1: any, target2: any) {
    return target1.getFullYear() === target2.getFullYear() && target1.getMonth() === target2.getMonth() && target1.getDate() === target2.getDate();
  }

  private checkClickClass(fullTypeDate: any, date: any) {
    const cellId = document.getElementById(`cell-${fullTypeDate.getMonth() + 1}-${date}`)?.parentElement;
    if (document.getElementsByClassName('click')[0]) document.getElementsByClassName('click')[0].classList.remove('click');
    if (cellId?.classList.contains('click')) cellId.classList.remove('click');
    else if (cellId !== null && cellId !== undefined) cellId.classList.add('click');
  }

  // 캘린더-날짜 선택 (연일 불가 타입)
  private choiceOneDate(fullDate: any, fullTypeDate: any, date: any) {
    if (this.prevChoiceFlag === false && moment(fullTypeDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
      this.$message({
        message: '현재보다 이전 날짜는 선택하실 수 없습니다.',
        type: 'warning',
      });
    } else {
      this.checkClickClass(fullTypeDate, date);
      this.$emit('choiceDate', moment(fullDate, 'YYYY.MM.DD').format('YYYY-MM-DD'));
    }
  }

  private clearClickClass() {
    if (document.getElementsByClassName('click')[0]) document.getElementsByClassName('click')[0].classList.remove('click');
  }

  private removeBetweenDate() {
    const elements = document.querySelectorAll('.date-cell.selected');
    elements.forEach((element) => {
      element.classList.remove('selected');
    });
    const start = document.querySelector('.date-cell.selected-start')?.classList.remove('selected-start');
    const end = document.querySelector('.date-cell.selected-end')?.classList.remove('selected-end');
  }

  private choiceBetweenDate() {
    // 시작일과 종료일의 사이에 있는 날짜 처리
    if (this.startDate && this.endDate) {
      let curDate = this.startDate;
      const lastDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 0);
      while (curDate <= this.endDate) {
        const targetDate = `${curDate.getFullYear()}-${curDate.getMonth() + 1}-${curDate.getDate()}`;
        curDate = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate() + 1);
        // 공휴일 체크 (dateInfo값이 없으면 무시)
        const dateInfo = this.calendarDataClone[moment(targetDate, 'YYYY-MM-DD').format('YYYY-MM-DD')];
        if (!dateInfo || !this.statusCheckClone(targetDate)) {
          const dayId = document.getElementById(`cell-${(curDate.getDate() - 1 === 0) ? curDate.getMonth() : curDate.getMonth() + 1}-${(curDate.getDate() - 1 === 0) ? lastDate.getDate() : curDate.getDate() - 1}`)?.parentElement;
          if (dayId !== null && dayId !== undefined && !dayId.classList.contains('click')) dayId.classList.add('selected');
          else if (dayId !== null && dayId !== undefined && (this.isSameDay(new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate() - 1), this.startDate))) {
            dayId.classList.add('selected-start');
          } else if (dayId !== null && dayId !== undefined && (this.isSameDay(new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate() - 1), this.endDate))) {
            dayId.classList.add('selected-end');
          }
        }
      }
    }
  }

  // 캘린더-날짜 선택 (연일 가능 타입)
  private choiceDays(fullTypeDate: Date, date: any) {
    if (this.prevChoiceFlag === false && moment(fullTypeDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
      this.$message({
        message: '현재보다 이전 날짜는 선택하실 수 없습니다.',
        type: 'warning',
      });
    } else {
      const cellId = document.getElementById(`cell-${fullTypeDate.getMonth() + 1}-${date}`)?.parentElement;
      if (cellId !== null && cellId !== undefined) {
        if (!this.startDate) {
          this.startDate = fullTypeDate;
          this.$emit('choiceDate', moment(this.startDate, 'YYYY.MM.DD').format('YYYY-MM-DD'), moment(this.startDate, 'YYYY.MM.DD').format('YYYY-MM-DD'));
          cellId.classList.add('click');
        } else if (!this.endDate && this.startDate) {
          if (this.startDate > fullTypeDate) {
            this.startDate = fullTypeDate;
            const ele = document.getElementsByClassName('click')[0]?.classList.remove('click');
            this.$emit('choiceDate', moment(this.startDate, 'YYYY.MM.DD').format('YYYY-MM-DD'), moment(this.startDate, 'YYYY.MM.DD').format('YYYY-MM-DD'));
            cellId.classList.add('click');
          } else if (this.startDate < fullTypeDate) {
            if (this.calendarType === 3 || !this.daysCheck(this.startDate, fullTypeDate)) {
              // if (this.count > 3) {
              if (false) {
                this.$message({
                  message: '4일 연속 신청 시 전화문의 바랍니다.',
                  type: 'warning',
                });
                this.startDate = null;
                this.endDate = null;
                this.removeClickClass();
              } else {
                this.endDate = fullTypeDate;
                cellId.classList.add('click');
                this.$emit('choiceDate', moment(this.startDate, 'YYYY.MM.DD').format('YYYY-MM-DD'), moment(this.endDate, 'YYYY.MM.DD').format('YYYY-MM-DD'));
                this.choiceBetweenDate();
              }
            } else {
              this.$message({
                message: '신청 불가능한 날짜입니다.',
                type: 'warning',
              });
            }
          }
        } else if (this.startDate && this.endDate) {
          this.removeBetweenDate();
          /* eslint-disable */
          new Promise((resolve) => {
            this.removeClickClass();
            resolve('');
          });
          this.startDate = fullTypeDate;
          this.endDate = null;
          this.$emit('choiceDate', moment(this.startDate, 'YYYY.MM.DD').format('YYYY-MM-DD'), moment(this.startDate, 'YYYY.MM.DD').format('YYYY-MM-DD'));
          cellId.classList.add('click');
        }
      }
    }
    this.count = 0;
  }

  private choiceDate(fullDate: any, fullTypeDate: any, date: any, disabled: boolean) {
    if (!disabled) {
      if (this.calendarType === 0 || this.calendarType === 4) {
      // if (this.calendarType === 1) {
        this.choiceOneDate(fullDate, fullTypeDate, date);
      } else this.choiceDays(fullTypeDate, date);
    } else {
      this.$message({
        message: '신청 불가능한 날짜입니다.',
        type: 'warning',
      });
    }
  }

  private visiblePreBtn() {
    if (this.selectedDay > this.today) return false;
    if (moment(this.selectedDay).format('YYYY-MM') === moment(this.today).format('YYYY-MM')) return false;
    return true;
  }

  private clickPrevMonth() {
    if (this.todayMonth === 1) {
      this.todayMonth = 12;
      this.todayYear = this.today.getFullYear() - 1;
      this.today = new Date(this.today.getFullYear() - 1, 11, 1);
    } else {
      this.todayMonth -= 1;
      this.today = new Date(this.today.getFullYear(), this.todayMonth - 1, 1);
    }
    this.$emit('changeMonth', this.today);
    // this.checkPrevMonth();
  }

  private clickNextMonth() {
    if (this.todayMonth === 12) {
      this.todayMonth = 1;
      this.todayYear = this.today.getFullYear() + 1;
      this.today = new Date(this.today.getFullYear() + 1, 0, 1);
    } else {
    this.todayMonth += 1;
      if (this.todayMonth === 12) this.today = new Date(this.today.getFullYear(), 11, 1);
      else this.today = new Date(this.today.getFullYear(), this.todayMonth - 1, 1);
    }
    this.$emit('changeMonth', this.today);
    // this.checkPrevMonth();
  }

  private setDate(startDate: Date, endDate: Date) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.todayYear = startDate.getFullYear();
    this.todayMonth = startDate.getMonth() + 1;
  }

  private checkPrevMonth(): boolean {
    if (moment(this.today).format('YYYY-MM') === moment().format('YYYY-MM') && this.prevChoiceFlag === false){
      return false;
    }
    return true;
  }
}
